@import (reference) "../_variables.less";

.praxis-cta {
	display: flex;
	flex-direction: column;
	max-width: 1060px;
	
	@media (min-width: @screen-laptop) {
		flex-direction: row;
		gap: 100px;
		
		> * {
			flex: 1;
		}
	}
}

.praxis-cta__left {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	
	@media(min-width: @screen-laptop) {
		justify-content: center;
		align-items: flex-start;
		text-align: left;
	}
}

.praxis-cta-logo {
	font-family: @font-family-header;
	font-size: @font-size;
	font-weight: @font-weight-bold;
	color: @color-gray-7;
	text-align: center;
	@media(min-width: @screen-laptop) {
		font-size: 25px;
		text-align: left;
	}
}

.praxis-cta-logo__test {
	color: @color-brand;
}

.praxis-cta__header {
	color: @color-header-black;
	font-size: 30px;
	font-weight: @font-weight-boldest;
	font-family: @font-family-header;
	line-height: normal;
	
	@media (min-width: @screen-laptop) {
		font-size: 48px;
		font-weight: @font-weight-bold;
		text-transform: capitalize;
	}
}

.praxis-cta__text {
	margin-top: 14px;
	padding: 0 24px;
	color: fade(@color-text-gray, 85%);
	font-size: @font-size;
	font-family: @font-family;
	line-height: 24px;
	
	@media (min-width: @screen-laptop) {
		padding: 0;
	}
}

.praxis-cta__bullets {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: @spacing-standard 0 0;
	list-style-position: inside;
	text-align: left;
	
	@media (min-width: @screen-laptop) {
		gap: 20px;
	}
	
	li {
		padding: 0;
	}
}

.praxis-cta__cta {
	font-family: @font-family-roboto;
	padding: @spacing-large 0;
	@media(min-width: @screen-laptop) {
		padding-bottom: 0;
	}
}

.praxis-cta--immediate-help {
	.praxis-cta__cta {
		display: none;
		
		@media (min-width: @screen-laptop) {
			display: block;
		}
	}
}

.praxis-cta__account {
	padding: 20px 60px;
	font-family: 'Roboto', sans-serif;
	box-shadow: none;
	
	@media (min-width: @screen-laptop) {
		font-size: @font-size-lg;
		font-weight: @font-weight-bold;
	}
}

.praxis-cta__right {
	display: flex;
	align-self: center;
	width: 100%;
}

.praxis-cta-image-container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.praxis-cta-image-container--personalized-practice {
	height: 62vw;
	
	@media (min-width: @screen-laptop) {
		height: 100%;
		max-height: 500px;
	}
}

.praxis-cta-image-container--lesson {
	border: 2px solid @color-purple;
	border-radius: 5px;
}

.praxis-cta-image {
	width: 100%;
	height: 100%;
}

.praxis-cta-image--cover {
	object-fit: cover;
}

.praxis-cta-image--contain {
	object-fit: contain;
}

.praxis-cta-image--personalized-practice {
	height: 375px;
	object-position: center -28vw;
	
	@media (min-width: @screen-tablet) {
		display: none;
	}
}

.praxis-cta-image__lesson-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	background-color: @color-purple;
	color: @color-white;
	text-align: center;
	font-family: @font-family-header;
	font-size: 12px;
	font-weight: @font-weight-bold;
	padding: 3px 0;
}

.praxis-cta-card-list {
	display: grid;
	gap: 30px;
	padding: 30px 20px 0;
	width: 100%;
	list-style-type: none;
	
	@media (min-width: @screen-laptop) {
		grid-template-columns: repeat(2, 218px);
		padding: 0;
	}
}

.praxis-cta-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	padding: 40px 75px;
	text-align: center;
	border-radius: 10px;
	box-shadow: 0 11px 26px 0 fade(@color-black, 15%);
	
	@media (min-width: @screen-laptop) {
		padding: 50px 60px;
	}
}

.praxis-cta-card__image {
	height: 60px;
	width: auto;
	
	@media(max-width: (@screen-laptop - 1px)) {
		height: 48px;
	}
}

.praxis-cta-card__detail {
	color: @color-black;
	font-size: 24px;
	font-weight: @font-weight-boldest;
	font-family: 'Montserrat', sans-serif;
}


.praxis-cta__testimonial {
	padding: 10px 0;
}

.praxis-cta__testimonial-metadata {
	text-transform: uppercase;
	font-size: 14px;
}

