@import (reference) "../../global/global-template.less";
@import (reference) "../_variables.less";

footer {
	background-color: @color-gray-2c;
	color: @color-white;
}

.footer-container {
	.width-and-gutters();
	padding: 80px 20px @spacing-standard;
	max-width: 1600px;
	
	@media (min-width: @screen-laptop) {
		padding: @spacing-xlarge 20px @spacing-standard;
	}
}

.footer-content {
	display: grid;
	grid-template-areas: "info"
								"state"
								"links";
	grid-template-rows: repeat(3, auto);
	grid-template-columns: 1fr;
	gap: @spacing-standard;
	
	@media (min-width: @screen-laptop) {
		grid-template-areas: "info links state";
		grid-template-columns: repeat(3, auto);
		grid-template-rows: 1fr;
		justify-content: space-around;
	}
}

.footer-section {
	max-width: 400px;
}

.footer-section--info {
	grid-area: info;
}

.footer-section--state {
	grid-area: state;
}

.footer-section--links {
	grid-area: links;
}

.footer-header {
	.font-h3();
	color: @color-white;
	margin-bottom: @spacing-standard;
}

.footer-header.footer-domain {
	font-size: 40px;
	font-weight: @font-weight-bold;
	margin-bottom: 20px;
}

.footer-link {
	.font-body();
	color: @color-white;
	text-decoration: none;
	font-size: @font-size-lg;
	line-height: 100%;
	
	&:visited {
		color: @color-white;
	}
}

.list-items {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0;
	list-style: none;
}

.state-grid {
	display: grid;
	justify-content: flex-start;
	grid-template-columns: repeat(7, minmax(30px, 1fr));
	grid-auto-flow: row;
	gap: 10px;
	letter-spacing: 0.24em;
	padding: 0;
	line-height: 100%;
	list-style: none;
}

.copyright {
	padding-top: @spacing-large;
	
	@media (min-width: @screen-laptop) {
		text-align: center;
	}
}