@import (reference) "_variables";
@import (reference) "../_functions";
@import (reference) "../mixins/_styleResets";
@import (reference) "../mixins/_icons";
@import (reference) "../global/components/top-breadcrumbs";

@import "../global/components/_image-and-details.less";
@import "../global/global-template";

@import "components/_topNav";
@import "components/_footer.less";
@import "components/_ctas.less";
@import "components/_praxisCta.less";

body {
	scroll-behavior: smooth;
}

main {
	overflow-wrap: break-word;
	padding-top: 0;
	padding-bottom: 0;
}

.color-box(@color) {
	padding: @spacing-standard;
	background-color: @color;

	@media (min-width: @screen-tablet) {
		padding: @spacing-large @spacing-standard;
	}

	@media (min-width: @screen-desktop) {
		padding: @spacing-large;
	}
}

.main-content section.content,
.main-content section > .content {
	position: relative;
	.width-and-gutters();
	
	@media (max-width: @screen-mobile-max) {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	padding-bottom: 80px;
	padding-top: 80px;
	
	@media (min-width: @screen-tablet) {
		padding-bottom: @spacing-xlarge;
		padding-top: @spacing-xlarge;
	}
	
	&.content--toc {
		padding-top: 0;
	}
	
	&.content--full-width {
		max-width: none;
	}
	
	&.content--content {
		a {
			font-weight: @font-weight-boldest;
			text-decoration: underline;
		}
	}
}

.purple-bleed {
	background-color: @color-purple-light;
}

.make-breadcrumbs(@color-white);

h4 {
	font-size: 18px;

	@media(min-width: @screen-tablet) {
		font-size: 24px;
	}

	font-family: @font-family-header;
	color: @color-gray-black;
}

button, input[type=button], a.button {
	font-size: 24px;
	font-weight: @font-weight-bold;
	text-align: center;
	vertical-align: center;
	line-height: @line-height-pct;
	text-decoration: none;
	color: white;

	background-color: @color-button;

	border: none;
	border-radius: 30px;

	min-height: 60px;
	max-width: 315px;
	padding: @spacing-paragraph;

	position: relative;
	cursor: pointer;

	&:active {
		background-color: @color-button-active;
		color: @color-link;
	}

	&.btn-blue {
		background-color: @color-link;

		&:active {
			color: @color-link;
			background-color: @color-purple-light;
		}
	}

	&.btn-salmon {
		color: @color-link;
		background-color: @color-salmon-light;

		&:active {
			background-color: @color-salmon;
		}
	}

	&.body-btn {
		min-width: 315px;
	}
}


.pull-quote {
	font-size: 28px;
	line-height: @line-height-pct;
}

.testimonial-text {
	font-size: 28px;
	color: @color-gray-black;
	font-weight: @font-weight-thin;
	line-height: @line-height-pct;
}

.testimonial-name {
	font-size: 22px;
	color: @color-gray-black;
	font-style: italic;
}

select {
	.font-body();
	background-color: @color-white;
	border-radius: 0;
	padding: @spacing-minimum @spacing-paragraph;
	border: 1px solid @color-gray-7;

	option {
		.font-body();
	}
}

.hero-action {
	margin-top: 20px;

	@media(min-width: @screen-tablet) {
		margin-top: @spacing-standard;
	}
}

.hero-action__label {
	margin: 0 0 20px;
	color: @color-purple;
	font-weight: @font-weight-boldest;
}

ul.article-list {
	.plain-ul();
	border-top: 1px solid @color-gray-a;

	li:before {
		.material-outline("arrow_right ");
		margin-right: -1em;
		position: relative;
		top: .125em;
	}

	li.hide-bullet:before {
		visibility: hidden;
	}

	li {
		border-bottom: 1px solid @color-gray-a;
		padding: @spacing-paragraph;
	}
}

ul.d-two-col {
	@media (min-width: @screen-tablet) {
		column-count: 2;
		column-gap: @spacing-standard;
		break-inside: avoid;

		li {
			break-inside: avoid;
		}
	}
}

ul.link-rows {
	.plain-ul();

	li {
		background-color: white;
		padding: @spacing-paragraph;

		&:not(:last-child) {
			margin-bottom: 3px;
		}

		transition: height .5s linear, margin-top .5s linear, margin-bottom .5s linear, padding-top .5s linear, padding-bottom .5s linear;

		&.collapsed {
			height: 0;
			visibility: hidden;
			padding: 0;
			margin: 0;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-decoration: none;

			.link-header {
				width: 140px;
				padding: 0 @spacing-paragraph;
			}

			.link-name {
				flex-grow: 1;
				text-align: left;
			}

			.link-action {
				text-align: right;
				.font-link();
				padding: 0 @spacing-paragraph;

				@media (max-width: @screen-mobile-max) {
					display: none;
				}
			}

			&:after {
				flex-grow: 0;
				flex-shrink: 0;
				.material("arrow_forward");
				margin-left: @spacing-minimum;
				.font-link();
			}
		}
	}
}

table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	font-family: @font-family-header;
	
	@media(max-width: @screen-mobile-max) {
		display: block;
		
		&:not(.responsive-table) {
			overflow-x: scroll;

			tbody {
				display: table;
			}
		}

		&.responsive-table {
			tbody {
				display: block;
			}

			tr {
				display: block;

				&:first-child {
					display: none;
				}

				&:nth-child(n + 3) {
					border-top: 1px solid @color-gray-border;
				}
			}
			
			td {
				display: flex;
				column-gap: @spacing-paragraph;
				padding: 0 20px 20px;
				align-items: center;

				border: none !important;

				&:first-child {
					padding-top: @spacing-standard;
				}
				
				&:last-child {
					padding-bottom: @spacing-standard;
				}
				
				&:before {
					content: attr(data-label);
					font-family: @font-family-header;
					color: @color-black;
					font-weight: @font-weight-bold;
					text-transform: none;
					text-align: left;
					flex: 0 0 ~"calc(50% - 15px)";
				}
			}
		}
	}

	max-width: 100%;

	tbody {
		width: 100%;
	}
	
	th, td {
		@media (min-width: @screen-tablet) {
			padding: @spacing-standard;
			
			&:first-child {
				padding-left: 0;
			}
			
			&:last-child {
				padding-right: 0;
			}
		}
	}
	
	th {
		.font-h3();
		text-transform: none;
		text-align: left;
		font-weight: @font-weight-bold;
		font-size: 20px;
		padding-bottom: 0;
		
		@media (min-width: @screen-tablet) {
			font-size: 24px;
		}
	}
	
	td {
		color: @color-text-gray;
		border-bottom: 1px solid @color-gray-border;
		font-family: @font-family-roboto;
		
		&:first-child {
			color: @color-purple;
			font-weight: @font-weight-bold;
			font-family: @font-family-header;
		}
	}
}

table.imageplugin {
	width: fit-content;
	
	td {
		padding: 0;
		border-bottom: none;
	}
}

.cta-divider.practice-test-divider {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: repeat(2, auto);
	gap: @spacing-standard;
	position: relative;
	padding: 20px;
	margin-top: 40px;
	
	background-color: @color-white;
	color: @color-header-dark;
	
	border-radius: 10px;
	box-shadow: 0 15px 40px fade(@color-black, 25%);
	text-align: center;
	
	@media (min-width: @screen-tablet) {
		padding: 50px;
		margin-top: 50px;
		grid-template-columns: 2fr 3fr;
		grid-template-rows: 1fr;
		
		text-align: left;
		
		border-radius: 15px;
		box-shadow: 0 11px 26px fade(@color-black, 15%);
	}
	
	.pull-quote {
		.font-h3();
		
		font-size: 30px;
		@media (min-width: @screen-tablet) {
			font-size: 34px;
		}
		// unset some weird defaults the other rule set
		max-width: unset;
		margin: 0;
	}
}

.dropdown-container {
	position: relative;
	display: flex;
	gap: @spacing-paragraph;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	margin: 0;
	
	@media (min-width: @screen-tablet) {
		justify-content: end;
	}
}

@action-dropdown-symbol-width: 30px;
.action-dropdown {
	display: inline-block;
	position: relative;
	max-width: 400px;
	flex-grow: 1;
	
	@media (min-width: @screen-tablet) {
		min-width: 315px;
	}
	
	font-family: @font-family-header;
	
	&::after {
		.material(@material-expand-more);
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		pointer-events: none;
		font-size: @action-dropdown-symbol-width;
		color: @color-brand;
		
		@media (min-width: @screen-tablet) {
			right: 15px;
		}
	}
}

.action-dropdown__button {
	width: 62px;
	height: 62px;
	min-height: auto;
	padding: 0;
	
	flex-shrink: 0;
	
	border-radius: 50%;
	overflow: hidden;
	background-color: @color-brand;
	font-weight: @font-weight;
	
	&:hover {
		background-color: @color-salmon-light;
	}
	
	&::after {
		.material(@material-chevron-forward);
		display: block;
	}
	
	@media (min-width: @screen-tablet) {
		width: 60px;
		height: 60px;
		font-weight: @font-weight-bolder;
	}
}

.action-dropdown__select {
	width: 100%;
	padding: 20px 10px + @action-dropdown-symbol-width 20px 20px;
	font-size: @font-size-sm;
	border: 1px solid @color-gray-border;
	border-radius: 100px;
	
	-moz-appearance: none;
	-webkit-appearance: none;
	
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
	
	&::-ms-expand {
		display: none;
	}
	
	@media (min-width: @screen-tablet) {
		padding: 20px 20px + @action-dropdown-symbol-width 20px 40px;
		font-size: @font-size;
	}
}

.c-brand {
	color: @color-brand;
}

p + ul {
	margin-top: @spacing-paragraph;
}

@import "../global/components/faqs";

// region faq override
.faqs__faq__header {
	color: @color-purple;
	font-family: @font-family-header;
	font-weight: @font-weight-bold;
	font-size: @font-size-lg;
	text-transform: capitalize;
}

.faqs__list {
	font-size: @font-size-lg;
}
// endregion faq override

@import "../global/components/tableOfContents";

// region table of contents override
.link-table {
	padding: 80px 50px;
	background-color: @color-white;
	border-radius: @border-radius-large;
	box-shadow: 0 8px 34px fade(@color-black, 15%);
	
	@media (min-width: @screen-tablet) {
		padding: @spacing-large 120px;
	}
}

.link-table__list {
	.plain-ul();
	.font-body();
	
	display: grid;
	grid-template-columns: auto;
	gap: 20px;
	
	@media (min-width: @screen-tablet) {
		gap: @spacing-standard;
		grid-template-columns: repeat(2, 1fr);
	}
}

.link-table__list-item {
	display: grid;
	grid-template-columns: 35px 1fr;
	align-items: center;
	gap: @spacing-paragraph;
	
	&::before {
		background-image: url(/resources/img/icons/icon-long-arrow-forward.svg);
		background-repeat: no-repeat;
		content: "";
		width: 100%;
		height: 15px;
		// convert this icon to @color-purple
		filter: invert(29%) sepia(14%) saturate(1802%) hue-rotate(215deg) brightness(98%) contrast(94%);
	}
}

.link-table__list-item-link {
	color: @color-brand;
	text-decoration: none;
	font-weight: @font-weight-boldest;
	&:hover {
		text-decoration: underline;
	}
}

.table-of-contents {
	.link-table();
	
	// arghh, negative margin for this overlapping effect that figma wants
	margin-top: -80px;
	@media (min-width: @screen-tablet) {
		margin-top: -130px;
	}
}

.table-of-contents__list {
	.link-table__list();
}

.table-of-contents__list-item {
	.link-table__list-item();
}

.table-of-contents__list-item-link {
	.link-table__list-item-link();
	
	text-decoration: underline;
}

.table-of-contents__header {
	// although it's a h6 tag in the html, I don't want to change it to h2 since it'll affect ALL microsites, so we'll just be h2 in CSS-land
	.font-h2();
	margin-bottom: @spacing-standard;
	text-align: center;
	
	@media (min-width: @screen-tablet) {
		text-align: left;
	}
}
// endregion table of contents overrides

.float-right {
	float: right;
}

.text-align-end {
	text-align: end;
}

.text-align-center {
	text-align: center !important;
}

.gray-e-bleed {
	background-color: @color-gray-e;
}

.gray-7 {
	color: @color-gray-7;
}

.gray-40 {
	color: @color-gray-40;
}