@import (reference) "../_variables.less";
@import (reference) "../../global/global-template.less";

#sign-up-button {
	background-color: @color-button;
	color: @color-white;
	font-size: 20px;
	line-height: 21px;
	display: flex;
	align-items: center;
}

nav.breadcrumbs {
	background-color: @color-white;
}

header.hero.with-cta {
	section.content {
		display: flex;
		align-items: center;
		
		@media(max-width: (@screen-laptop - 1px)) {
			flex-direction: column;
			row-gap: @spacing-standard;
			
			.hero-cta {
				flex: auto;
				margin-right: 0;
				width: 100%;
			}
		}
	}
	
	h1 {
		text-align: left;
		flex: 1 1 100%;
	}
}

.hero-cta {
	flex: 0 0 430px;
	text-align: left;
	border-radius: 6px;
	border: 1px solid @color-highlight;
	background-color: @color-white;
	padding: @spacing-standard;
	margin-right: @spacing-standard;
}

.hero-cta__title {
	font-size: 24px;
	line-height: 31px;
	font-weight: @font-weight-bold;
	font-family: @font-family-header;
	color: @color-brand;
}

.hero-cta__prompt {
	font-size: 16px;
	line-height: 22.4px;
	font-family: @font-family-header;
	padding-top: @spacing-paragraph;
}

.hero-cta__select {
	width: 100%;
	font-size: 16px;
}

.hero-cta__button {
	padding-top: @spacing-paragraph;
}

.hero-cta__create-account {
	display: inline-block;
	background-color: @color-brand;
	color: @color-white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	font-size: 24px;
	line-height: 33.6px;
	font-family: @font-family-header;
	font-weight: @font-weight-bold;
	padding: 13px @spacing-large;
}

.praxis-cta-wrapper {
	margin-top: @spacing-large;
	margin-bottom: @spacing-large;
	border-top: 1px solid @color-purple-light;
	border-bottom: 1px solid @color-purple-light;
}

.cta__create-account {
	display: inline-block;
	background-color: @color-royal;
	color: @color-white;
	font-weight: @font-weight-bold;
	font-size: 24px;
	font-family: @font-family-header;
	padding: 18px @spacing-standard;
	border-radius: @spacing-standard;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	
	@media (max-width: @screen-mobile-max) {
		width: 100%;
		text-align: center;
	}
	
	&:hover {
		background-color: @color-salmon-light;
		text-decoration: none;
	}
}

.cta-footer {
	background-color: rgba(255, 176, 135, 0.5);
	padding: 45px @spacing-large;
	margin: 0;
	
	@media (max-width: @screen-tablet) {
		padding: @spacing-standard;
	}
	
	.praxis-cta__header {
		text-align: center;
		padding: 0;
		font-size: 24px;
		font-weight: @font-weight;
	}
	
	.praxis-cta__cta {
		text-align: center;
	}
	
	.cta__create-account {
		&:hover {
			background-color: @color-royal-dark;
		}
	}
	
}

.practice-test-results-cta {
	width: 100%;
	
	.praxis-cta-wrapper {
		border: none;
		margin: @spacing-standard 0;
	}
	
	.praxis-cta {
		padding: @spacing-standard;
		background-color: @color-gray-e;
		border-radius: 6px;
	}
	
	.praxis-cta__logo {
		display: none;
	}
	
	.praxis-cta__cta {
		display: none;
	}
	
	@media(min-width: @screen-tablet) and (max-width: (@screen-laptop - 1px)) {
		.praxis-cta {
			flex-direction: column;
		}
		
		.praxis-cta__icon-with-label {
			flex: 0 0 25%;
		}
		
		.praxis-cta__right {
			flex: revert;
		}
	}
}

.practice-test-results-sign-up-button {
	@media (max-width: @screen-tablet) {
		width: 100%;
	}
}

.individual-test .exam-container aside.practice-test-results-sign-up-button {
	@media (min-width: @screen-laptop) {
		display: none;
	}
}

.testimonial-cta {
	.praxis-cta__testimonial-body {
		font-size: 18px;
		color: @color-gray-40;
	}
	
	.praxis-cta__testimonial-metadata {
		font-size: 14px;
		color: @color-gray-40;
	}
	
	.praxis-cta__cta {
		padding-top: 20px;
	}
	
	.purple-hr {
		display: none;
		@media (min-width: @screen-laptop) {
			display: block;
			margin: 0 0 5px;
			width: 100%;
			border-top: 1px solid @color-purple-light;
		}
	}
}

section.content.white-bleed-all.image-and-detail__bleed-tablet {
	background: none;
	
	@media(max-width: @screen-mobile-max) {
		padding-bottom: 0;
	}
	
	@media(min-width: @screen-tablet) {
		background: url(/resources/img/71/tablet-score.png) 200px bottom #fff no-repeat;
		background-size: 700px;
		padding-bottom: 50px;
		
		.image-and-detail__image-container--mobile {
			display: none;
		}
	}
	
	@media(min-width: @screen-laptop) {
		background: url(/resources/img/71/tablet-score.png) 270px bottom #fff no-repeat;
		background-size: 1000px;
	}
	
	@media(min-width: @screen-xl) {
		background: url(/resources/img/71/tablet-score.png) right -100px bottom #fff no-repeat;
		background-size: 1000px;
	}
	@media(min-width: @screen-xxl) {
		background: url(/resources/img/71/tablet-score.png) right -100px bottom no-repeat #fff;
		background-size: 1210px;
	}
	@media(min-width: 1700px){
		background: url(/resources/img/71/tablet-score.png) right bottom/cover no-repeat #fff;
		background-size: auto;
	}
	
	.image-and-detail__image-container {
		display: none;
		@media(min-width:@screen-tablet) {
			display: block;
			height: 180px;
		}
	}
	
	.image-and-detail {
		gap: 0;
		@media (min-width: @screen-tablet) {
			margin-bottom: 0;
		}
		@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max){
			flex-direction: column;
		}
	}
	
	.image-and-detail__content {
		margin-bottom: 0;
	}
	
	.pill-button {
		margin: 0;
	}
}
