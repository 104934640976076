@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";
@import (reference) "../../mixins/_icons";

@topnav-mobile-height: 60px;
@logo-width: 235px;

.topnav-desktop() {
	padding: @spacing-standard 20px;
	
	.topnav__sections {
		display: flex;
		column-gap: 2.5vw;
		justify-content: flex-end;
		align-items: center;
		height: 100%;
		flex: 1;
		margin-left: @spacing-standard;
	}

	.topnav__section {
		position: relative;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.section__title {
			align-self: center;
			text-align: center;
			color: @color-text-gray;
			font-weight: @font-weight;
			font-family: @font-family-roboto;

			&:focus-visible {
				outline: none;
			}

			&:hover {
				text-decoration: none;
			}
			
			&:after {
				.material(@material-expand-more);
				display: inline-block;
				border: none;
				vertical-align: middle;
				margin: 0;
				position: relative;
				top: -1px;
				font-size: @font-size-lg;
			}
			
			@media (min-width: @screen-laptop) {
				font-size: @font-size-sm;
				white-space: nowrap;
			}
			
			@media (min-width: @screen-xl) {
				font-size: @font-size;
			}
			
			@media (min-width: @screen-xxl) {
				font-size: @font-size-lg;
			}
			
			.topnav__suitename {
				display: none;

				@media (min-width: @screen-laptop) {
					display: inline;
				}
			}
		}

		.section__body {
			display: none;
			position: absolute;
			top: 100%;
			z-index: 200;
			left: -@spacing-standard;
			max-width: 600px;
			width: 600px;

			background: @color-white;
			border: 1px solid @color-gray-e;
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			box-shadow: 1px 4px 4px rgba(0, 0, 0, .25);

			li {
				padding: @spacing-paragraph/2 @spacing-standard;

				&:hover {
					background-color: @color-salmon-light;
				}
			}
		}

		&:hover {
			.section__body {
				display: block;

				&.section__body--state-grid {
					display: grid;
				}
			}
		}
		
		#login-button {
			color: @color-royal;
			font-weight: @font-weight-bold;
		}
	}
	
	.topnav__section.authentication {
		.section__title:after {
			display: none;
		}
	}

	.topnav__menu {
		display: none;
	}
}

.topnav-mobile() {
	padding: 15px 20px;
	justify-content: space-between;

	.topnav__sections {
		overflow: hidden;
		position: absolute;
		top: @topnav-mobile-height;
		left: 0;
		background-color: @color-white;

		width: 100%;

		visibility: hidden;
		overflow-y: scroll;
		height: 0;
		transition: height .5s ease, visibility 0s linear .5s;

		&.show {
			visibility: visible;
			overflow-y: scroll;
			height: ~"calc(100vh - @{topnav-mobile-height})";
			transition: height .5s ease, visibility 0s linear 0s;
			
			// bit gross with the ! but its needed to override some inline styling set by javascript in Collapsible.ts
			max-height: none !important;
		}
	}

	.topnav__section {
		overflow: hidden;
		position: relative;
		
		&:not(:last-child) {
			border-bottom: 1px solid #B9B9B9;
		}

		.section__title {
			.font-body();
			padding: @spacing-standard;
			display: block;
			font-size: @font-size-xl;
			font-weight: @font-weight-bolder;

			&:after {
				.material("expand_less");
				background-color: @color-gray-a;
				padding: @spacing-standard;
				position: absolute;
				top: 0;
				right: 0;
				font-size: 24px;
			}

			&.collapsed {
				background-color: white;

				&:after {
					.material("expand_more");
					background-color: @color-gray-e;
				}
			}
		}

		.section__body {
			max-height: 0;
			transition: max-height .5s ease-out;

			&.show {
				max-height: 500px;
				transition: max-height .5s ease-in;
			}

			li {
				padding: 10px 0;
				margin: 0 @spacing-standard;

				&:not(:last-child) {
					border-bottom: 1px solid @color-gray-e;
				}
			}

			&.section__body--state-grid {
				li {
					border-bottom: none !important;
				}
			}
		}
	}
	
	.authentication {
		.section__title:after {
			display: none;
		}
	}
}

.topnav {
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;

	width: 100%;
	background-color: @color-bg-light;
	z-index: 100;

	ul {
		.plain-ul();
	}

	
	.topnav-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 1600px
	}
	
	.section__body {
		&.section__body--state-grid {
			display: grid;

			grid-template-columns: repeat(auto-fill,minmax(2em,1fr));

			&.show {
				padding: 10px;
			}

			@media (min-width: @screen-tablet) {
				width: 400px !important;
				padding: 10px;
			}

			column-gap: 15px;
			row-gap: 10px;

			li {
				margin: 0 !important;
				padding-left: 0 !important;
				padding-right: 0 !important;
				text-align: center;
			}
		}
		
		a {
			color: @color-text-gray;
			font-weight: @font-weight;
			display: block;
		}
	}

	.logo {
		font-family: 'Oswald', sans-serif;
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		color: @color-gray-7;

		display: inline-block;
		text-decoration: none !important;

		text-transform: none;

		
		@media (min-width: @screen-tablet) {
			display: flex;
			align-items: center;
			font-size: 40px;
			line-height: 100%;
			width: @logo-width;
			height: 100%;
		}
	}
	
	.topnav__menu {
		&:after {
			.material("close");
			display: block;
			font-size: 24px;
		}

		&.collapsed:after {
			.material("menu");
			font-size: 30px;
		}

		color: @color-purple;
		text-decoration: none;
	}

	@media(max-width: (@screen-xl - 1)) {
		.topnav-mobile();
	}

	@media (min-width: @screen-xl) {
		.topnav-desktop();
	}
}

.topnav-scrolled {
	box-shadow: 0 4px 4px fade(@color-black, 25%);
}

.topnav__section.sign-up #sign-up-button {
	display: flex;
	align-items: center;
	background-color: @color-royal;
	font-size: @font-size-lg;
	font-weight: @font-weight-bold;
	line-height: 21px;
	min-height: 0;
	border-radius: 100px;
	max-width: fit-content;
	padding: 15px 30px;
	transition: 0.2s;
	
	margin: 15px auto;
	
	&:hover,
	&:active {
		background-color: @color-salmon-light;
	}
	
	@media (min-width: @screen-laptop) {
		font-size: @font-size-xl;
		padding: 20px 40px;
		white-space: nowrap;
		margin: @spacing-standard auto;
	}
	
	@media(min-width: @screen-xl) {
		font-size: @font-size;
		margin: 0;
	}
	
	@media(min-width: @screen-xxl){
		font-size: @font-size-lg;
		padding: 20px 45px;
	}
	
	@media (max-width: @screen-tablet-max) {
		font-size: @font-size-lg;
		padding: 20px 40px;
	}
}

#my-account-nav {
	span {
		@media (min-width: @screen-tablet) {
			display: none;
		}
	}
	
	&:before {
		font-size: 24px;
		.material(@material-profile);
		vertical-align: text-bottom;
		
		@media (max-width: @screen-tablet) {
			margin-right: 5px;
		}
	}
}